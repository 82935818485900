import { render, staticRenderFns } from "./MainView.vue?vue&type=template&id=384491e1&scoped=true&lang=pug&"
import script from "./MainView.vue?vue&type=script&lang=js&"
export * from "./MainView.vue?vue&type=script&lang=js&"
import style0 from "./MainView.vue?vue&type=style&index=0&id=384491e1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "384491e1",
  null
  
)

export default component.exports
<template lang="pug">
.content
  section.wrap(style="padding: 25px;")
    a-row(:gutter="12")
      a-col()
        a-card(style="margin-bottom: 12px;")
          .echart_wrap(ref="echart_wrap")
          ul.echart_tip
            li
              .icon
              span 查询数量
            li
              .icon
              span 结果数量

          //- pre {{ xAxis_data }}
          //- pre {{ series_data_1 }}
          //- pre {{ series_data_2 }}
          //- pre {{ data }}


    //- a-row(:gutter="12")
      a-col(:xs="12" :sm="12" :md="12" :lg="12" :xl="12" :xxl="10")
        a-row(:gutter="12")
          a-col(:span="24")
            a-card(style="height:356px; margin-bottom: 12px;")
              //- pre(style='font-size:12px;') {{ account }}
        
        a-row(:gutter="12")
          a-col(:span="12")
            a-card(style="height:176px; margin-bottom: 12px;")
              h3 role
              //- pre(style='font-size:12px;') {{ role }}
          a-col(:span="12")
            a-card(style="height:176px; margin-bottom: 12px;")
              h3 auths
              //- pre(style='font-size:12px;') {{ auths }}

      a-col(:xs="12" :sm="12" :md="12" :lg="12" :xl="12" :xxl="8")
        a-row(:gutter="12")
          a-col(:span="12")
            a-card(style="height:266px; margin-bottom: 12px;")
              h3 总数据数量
              p Content of no border type. Content of no border type. Content of no border type. Content of no border type.
          a-col(:span="12")
            a-card(style="height:266px; margin-bottom: 12px;")
              h3 总用户数量
              p Content of no border type. Content of no border type. Content of no border type. Content of no border type.

        a-row(:gutter="12")
          a-col(:span="12")
            a-card(style="background-color:#757575; color:#fff; height:266px; margin-bottom: 12px;")
              h3 menus
              //- pre(style='font-size:12px;') {{ menus }}
          a-col(:span="12")
            a-card(style="background-color:#19ad79; color:#fff; height:266px; margin-bottom: 12px;")
              h3 vuex_state
              //- pre(style='font-size:12px;') {{ vuex_state }}

      a-col(:xs="0" :sm="0" :md="0" :lg="0" :xl="0" :xxl="6")
        a-card(:padding="0" style="margin-bottom: 12px;")
          div(slot="title") 会员概览
          .p-15.flex.flex-x-between.flex-y-center
            span.fs-24 会员总数
            span.fs-24 {{ total }}
          Table(ref="table" height="380" :loading="loading" :columns="columns" :data="data" class="no-border")
            template(slot-scope="{ row }" slot="realname")
              strong(v-if="row.last_name && row.first_name") {{ row.last_name + row.first_name }}
            template(slot-scope="{ row }" slot="mobile")
              | {{ row.mobile }}
            template(slot-scope="{ row }" slot="registered")
              span {{ row.registered }}
          .p-15
            span more

    //- a-row(:gutter="12")
      a-col(:xs="6" :sm="6" :md="6" :lg="6" :xl="6" :xxl="4")
        a-card(style="background-color:#19ad79; color:#fff; height:145px; margin-bottom: 12px;")
          div(slot="title") 月营业额度
          h3 ￥23，000
          div + 比上月同期少 65%
          .progress
            .progress-bar
        a-card(style="background-color:#7252d3; color:#fff; height:145px; margin-bottom: 12px;")
          div(slot="title") 月营业额度
          h3 ￥23，000
          div + 比上月同期少 65%
          .progress
            .progress-bar
        a-card(style="background-color:#fff; color:#333; height:145px; margin-bottom: 12px;")
          div(slot="title") 月营业额度
          p Content of no border type. Content of no border type. Content of no border type. Content of no border type.

      a-col(:xs="18" :sm="18" :md="18" :lg="18" :xl="10" :xxl="12")
        a-card(:padding="0" style="margin-bottom: 12px;")
          //- pre {{ config }}
          //- #echarts(style="width:100%; height:458px;")

      a-col(:xs="0" :sm="0" :md="0" :lg="0" :xl="0" :xxl="8")
        a-card(style="margin-bottom: 12px;")
          p(slot="title") 营收排行
          a-list(:border="false")
            a-list-item This is a piece of text.
            a-list-item This is a piece of text.
            a-list-item This is a piece of text.
            a-list-item This is a piece of text.
            a-list-item This is a piece of text.
            a-list-item This is a piece of text.
            a-list-item This is a piece of text.
            a-list-item This is a piece of text.

      a-col(:xs="0" :sm="0" :md="0" :lg="0" :xl="8" :xxl="0")
        a-card(style="margin-bottom: 12px;")
          div(slot="title") title
          div AAA of no border type. Content of no border type. Content of no border type. Content of no border type.
          a-date-picker(type="date" placeholder="" style="width: 200px")

    //- a-row(:gutter="12")
      a-col(:xs="12" :sm="12" :md="12" :lg="12" :xl="12" :xxl="16")
        a-card(style="margin-bottom: 12px;")
          div(slot="title") 统计分析
          <div class="flex flex-x-between">
            <div>
              <h1>使用</h1>
              <h3>新增和启动</h3>
              <h3>活跃分析</h3>
              <h3>时段分析</h3>
            </div>
            <div>
              <h1>留存</h1>
              <h3>用户留存</h3>
              <h3>流失用户</h3>
              <ul>
                <li v-for="brand in brands" :key="brand">{{ brand }}</li>
              </ul>
            </div>
            <div id="pie" style="width:300px; height:300px;"></div>
          </div>

      a-col(:xs="12" :sm="12" :md="12" :lg="12" :xl="12" :xxl="8")
        a-card(style="margin-bottom: 12px;")
          p 111 of no border type. Content of no border type. Content of no border type. Content of no border type.

    //- a-row(:gutter="12")
      a-col(:xs="8" :sm="8" :md="8" :lg="8" :xl="8" :xxl="0")
        a-card(style="margin-bottom: 12px;")
          div(slot="title") Title
          p 111 of no border type. Content of no border type. Content of no border type. Content of no border type.

      a-col(:xs="6" :sm="6" :md="6" :lg="6" :xl="6" :xxl="6")
        a-card(style="margin-bottom: 12px;")
          div(slot="title") Title
          p 111 of no border type. Content of no border type. Content of no border type. Content of no border type.

      a-col(:xs="0" :sm="0" :md="0" :lg="0" :xl="0" :xxl="4")
        a-card(style="background-color:#10cfbd; color:#fff; margin-bottom: 12px;")
          div(slot="title") Title
          p 111 of no border type. Content of no border type.<br/>Content of no border type. Content of no border type.

      a-col(:xs="0" :sm="0" :md="0" :lg="0" :xl="0" :xxl="4")
        a-card(style="margin-bottom: 12px;")
          div(slot="title") Title
          p 111 of no border type. Content of no border type.<br/>Content of no border type. Content of no border type.

      a-col(:xs="10" :sm="10" :md="10" :lg="10" :xl="10" :xxl="10")
        a-card(style="margin-bottom: 12px;")
          div(slot="title") Title
          p 111 of no border type. Content of no border type.<br/>Content of no border type. Content of no border type.
</template>

<script>
import echarts from 'echarts';
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'MainView',
  components: {},
  data () {
    return {
      msg: '控制台',
      //- chart: null,
      //- chart2: null,
      loading: false,
      columns: [
        {
          title: 'mobile',
          slot: 'mobile'
        },
        {
          title: 'realname',
          slot: 'realname',
          width: 100,
        },
        {
          title: 'registered',
          slot: 'registered',
          width: 100,
          align: 'center'
        }
      ],
      page: 1,
      pageSize: 10,
      total: 0,
      //- data: [],
      // 
      
      opinion:['直接访问','邮件营销','联盟广告','视频广告','搜索引擎'],
      opinionData:[
        {value:335, name:'直接访问'},
        {value:310, name:'邮件营销'},
        {value:234, name:'联盟广告'},
        {value:135, name:'视频广告'},
        {value:1548, name:'搜索引擎'}
      ],
      //-

      data: [],
      chart: null,
      dataObj: this.value
    }
  },
  computed: {
    ...mapState({
      //- vuex_state: state => state.system.models.map(item => {
      //-   let _item = Object.assign({}, item);
      //-   //- delete _item.attributes;
      //-   delete _item.column;
      //-   return _item;
      //- }),
      //- menus: state => state.system.menus,
      //- account: state => state.account,
      //- role: state => state.system.role,
      //- auths: state => state.system.capabilities,
      //- config: state => state.system.config
    }),
    brands () {
      return [
        'brands.nike',
        'brands.adi',
        'brands.nb',
        'brands.ln'
      ]
    },
    
    xAxis_data() {
      const days = [];
      this.data.map(item => {
        days.push(moment(item.created_at).format('YYYY-MM-DD'))
      });
      return Array.from(new Set(days));
    },
    series_data_1() {
      const counts = {};
      this.data.map(item => {
        const day = moment(item.created_at).format('YYYY-MM-DD')
        if (!counts[day]) {
          counts[day] = 1
        } else {
          ++counts[day]
        }
      });
      return Object.values(counts);
    },
    series_data_2() {
      const counts = {};
      this.data.map(item => {
        const day = moment(item.created_at).format('YYYY-MM-DD')
        const result = item.search_result ? JSON.parse(item.search_result) : { Count: 0 }
        if (!counts[day]) {
          counts[day] = result.Count
        } else {
          counts[day] = counts[day] + result.Count
        }
      });
      return Object.values(counts);
    }
  },
  methods: {
    dd (name) {
      switch (name) {
        case 'user':
          this.$router.push({ path: '/admin/user' })
          break
        case 'role':
          this.$router.push({ path: '/admin/role' })
          break
        case 'setting':
          this.$router.push({ path: '/admin/setting' })
          // this.$router.replace({ path: '/admin/setting' })
          break
      }
    },
    
    /**
     * 加载数量
     */
    get_total () {
      this.$http.get('/user/count', {
        params: {
          role: 'member'
        }
      }).then(res => {
        this.total = res
      }).catch(err => {
        this.$message.info(err.message)
      })
    },
    // 加载数据
    get_date () {
      this.loading = true
      this.$http.get('/user', {
        // params: {
        //   paged: this.page,
        //   limit: this.pageSize
        // }
      }).then(res => {
        this.loading = false

        let ids = []
        res.map(r => {
          ids.push(r.id)
          r.registered = moment(r.registered).format('YYYY-MM-DD hh:m:ss')
        })
        this.data = res
        
        ids = [...new Set(ids)]
        // this.get_trans_total(ids)
        // this.get_vein_total(ids)
      }).catch(err => {
        this.loading = false
        this.$message.error(err.message)
      })
    },
    
    //-
    
    query () {
      // statistics
      this.$http.get('/analysis').then(res => {
        this.data = res
        this.initCharts()
      }).catch(err => {
        console.log(err)
      })
    },
    initCharts() {
      var base = +new Date(1968, 9, 3);
      var oneDay = 24 * 3600 * 1000;
      var date = [];

      var data = [Math.random() * 300];

      for (var i = 1; i < 20000; i++) {
        var now = new Date(base += oneDay);
        date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'));
        data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
      }

      this.charts = echarts.init(this.$refs['echart_wrap'])
      this.charts.setOption({
        title: {
          left: 'left',
          text: 'AI使用趋势',
        },
        tooltip: {
          trigger: 'axis'
        },
        // legend: {
        //   textStyle: {
        //     color: '#FFFFF99'
        //   },
        //   icon: 'roundRect'
        // },
        xAxis: {
          data: this.xAxis_data,
          axisLine: {
            lineStyle: {
              color: '#cccccc'
            }
          },
          // name: '年-月'
          // type: 'category',
          // boundaryGap: false
        },
        yAxis: {
          type: 'value',
          // boundaryGap: [0, '100%']
          axisLine: {
            lineStyle: {
              color: '#ffffff'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#cccccc'
            }
          }
        },
        grid: {
          top: 86,
          left: 46,
          right: 46,
          bottom: "10%"
        },
        series: [
          {
            type: 'line',
            smooth: true,
            name: '查询数量',
            color: '#9956FB',
            data: this.series_data_1
          },
          {
            type: 'line',
            smooth: true,
            name: '结果数量',
            color: '#EC4BD6',
            data: this.series_data_2
          },
        ]
      })
    },
    onResize() {
      if (this.charts) this.charts.resize()
    },
  },
  watch: {
    value() {
      this.dataObj = {}
      this.dataObj = this.value
      this.initCharts()
    }
  },
  mounted() {
    this.query()
    window.addEventListener("resize", this.onResize, false)
    // EGG OPEN 接口测试
    //- this.$http.get('/attachment').then(res => {
    //-   console.log(res)
    //- })

    // this.get_total()
    // this.get_date()
  },
  beforeCreate () {
    // document.getElementsByTagName('body')[0].className = `${document.getElementsByTagName('body')[0].className} dashboard`
  },
  beforeDestroy () {
    // document.body.removeAttribute('class', 'dashboard')
    if (!this.chart) return
    this.chart.dispose()
    this.chart = null
    window.removeEventListener("resize", this.onResize, false)
  }
}
</script>

<style lang="less" scoped>
.relative { position: relative;}
.echart_wrap { height: 480px;}
.echart_tip {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  position: absolute; top: 31px; right: 46px;
  display: flex;
  li {
    margin-right: 20px;
    display: flex; align-items: center;
    &:first-child .icon {
      &::before {
        background: #9956FB;
      }
      &::after {
        background: #9956FB;
      }
    }
    &:last-child .icon {
      &::before {
        background: #EC4BD6;
      }
      &::after {
        background: #EC4BD6;
      }
    }
  }
  .icon {
    position: relative; padding: 0 15px;
    &::before {
      content: '';
      width: 26px; height: 2px; display: block;
      border-radius: 1px;
      position: absolute; top: 50%; left: 50%; z-index: 0;
      transform: translate3d(-50%, -50%, 0);
    }
    &::after {
      content: '';
      width: 12px; height: 12px; display: block;
      border: 2px solid #fff; border-radius: 50%;
      position: relative; z-index: 1;
    }
  }
}
</style>